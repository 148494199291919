<template>
  <div class="page-wrapper container">
    <h1 class="page-title" data-aos="fade-up">Academy</h1>
    <div class="page-desc" data-aos="fade-up">
      <span>{{ description }}</span>
    </div>

    <div class="app-data-filter-wrapper">
      <AppDataFilterOpenButton @click="handleOpenFilter" />
    </div>

    <MsgEntityCollectionEmpty
      v-if="!loadingAcademies && !itemsScheduled.length && !itemsByRequest.length"
      name="Academies"
    />

    <div v-if="itemsScheduled.length">
      <h2 class="event-section-title">UPCOMING CLASSES</h2>
      <div class="content-wrp row-considering-mobile">
        <EntityCard
          v-for="academy in itemsScheduled"
          :key="academy.id"
          class="col-12 col-md-6 col-lg-4"
          :data="academy"
          data-aos="fade-up"
          type="academy"
        />
      </div>
      <button v-if="getMore.itemsScheduled" class="btn-to-load-more-events" @click="loadMore('itemsScheduled')">
        Load more
      </button>
    </div>

    <div v-if="itemsByRequest.length">
      <h2 class="event-section-title">REQUEST A CLASS</h2>
      <div class="content-wrp row-considering-mobile">
        <EntityCard
          v-for="academy in itemsByRequest"
          :key="academy.id"
          class="col-12 col-md-6 col-lg-4"
          :data="academy"
          data-aos="fade-up"
          type="academy"
        />
      </div>
      <button v-if="getMore.itemsByRequest" class="btn-to-load-more-events" @click="loadMore('itemsByRequest')">
        Load more
      </button>
    </div>

    <AppDataFilterMain
      v-model="filterValues"
      :filter-groups="filterGroups"
      :init-open="isAppDataFilterOpened"
      :is-mobile="isMobileScreen"
      @input="handleChangeFilterValues"
      @open="handleOpenFilter"
      @close="handleCloseFilter"
    />
  </div>
</template>

<script>
import { areRouteQueriesDifferent } from '@/helpers/routerHelper';
import { EntityTypes } from '@/helpers/entityType';
import AcademyDataFilterHelper from '@/helpers/data-filter/AcademyDataFilterHelper';

import pageMetadataQuery from '@/graphql/PageMetadata.query.gql';
import academiesByRequestQuery from '@/graphql/academy/AcademiesByRequest.query.gql';
import academiesByScheduleQuery from '@/graphql/academy/AcademiesBySchedule.query.gql';
import academiesPageFilterQuery from '@/graphql/academy/AcademiesPageFilter.query.gql';

import AppDataFilterMain from '@/components/data-filter/AppDataFilterMain';
import AppDataFilterOpenButton from '@/components/data-filter/AppDataFilterOpenButton';
import EntityCard from '@/components/partials/EntityCard';
import MsgEntityCollectionEmpty from '@/components/MsgEntityCollectionEmpty.vue';

const DATA_PER_PAGE = 6;

export default {
  name: 'AcademiesPage',
  components: {
    MsgEntityCollectionEmpty,
    AppDataFilterOpenButton,
    AppDataFilterMain,
    EntityCard,
  },
  metaInfo: {
    title: 'Academies',
  },
  data() {
    return {
      description: '',
      itemsScheduled: [],
      itemsByRequest: [],
      loading: {
        itemsScheduled: true,
        itemsByRequest: true,
      },
      getMore: {
        itemsScheduled: false,
        itemsByRequest: false,
      },
      page: {
        itemsScheduled: 1,
        itemsByRequest: 1,
      },
      filterGroups: [],
      filterValues: {},
      changingRouteAfterFilterUpdated: false,
    };
  },
  computed: {
    filterQueryVariables() {
      return AcademyDataFilterHelper.prepareFilterValuesToQueryVariables(this.filterValues);
    },
    isAppDataFilterOpened() {
      return !!this.$store.state.isAppDataFilterOpened;
    },
    isMobileScreen() {
      return !!this.$store.state.isMobileScreen;
    },
    loadingAcademies() {
      return this.loading.itemsScheduled || this.loading.itemsByRequest;
    },
  },
  created() {
    this.filterValues = AcademyDataFilterHelper.getFilterValuesFromRouteQuery(this.$route.query);
    this.fetchAllAcademies();
    this.getFilterData();
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.changingRouteAfterFilterUpdated) {
      this.filterValues = AcademyDataFilterHelper.getFilterValuesFromRouteQuery(to.query);
    }
    this.resetFetchDataOptions();
    this.fetchAllAcademies();
    this.changingRouteAfterFilterUpdated = false;
    next();
  },
  apollo: {
    pageMetadata: {
      query: pageMetadataQuery,
      variables() {
        return {
          entityType: EntityTypes.academy,
        };
      },
      result(result) {
        this.description = result.data.pageMetadata?.description;
      },
    },
  },
  methods: {
    fetchAllAcademies() {
      this.fetchAcademiesData('itemsScheduled');
      this.fetchAcademiesData('itemsByRequest');
    },
    fetchAcademiesData(type) {
      this.loading[type] = true;

      const query = type === 'itemsScheduled' ? academiesByScheduleQuery : academiesByRequestQuery;
      this.$apollo
        .query({
          query,
          fetchPolicy: 'no-cache',
          variables: {
            limit: DATA_PER_PAGE,
            page: this.page[type],
            ...this.filterQueryVariables,
          },
        })
        .then(({ data }) => {
          this[type].push(...data.academies.data);
          this.getMore[type] = data.academies.hasMorePages;
          this.loading[type] = false;
        })
        .catch(() => {
          this.loading[type] = false;
        });
    },
    getFilterData() {
      return this.$apollo
        .query({
          query: academiesPageFilterQuery,
          fetchPolicy: 'no-cache',
        })
        .then(({ data = {} }) => {
          this.filterGroups = AcademyDataFilterHelper.prepareFilterGroups(data);
        });
    },
    handleOpenFilter() {
      this.$store.dispatch('toggleAppDataFilter', true);
    },
    handleCloseFilter() {
      this.$store.dispatch('toggleAppDataFilter', false);
    },
    handleChangeFilterValues() {
      const query = AcademyDataFilterHelper.setFilterValuesToRouteQuery(this.filterValues);

      if (areRouteQueriesDifferent(this.$route.query, query)) {
        this.changingRouteAfterFilterUpdated = true;
        this.$router.push({ name: this.$route.name, params: this.$route.params, query });
      }
    },
    loadMore(type) {
      switch (type) {
        case 'itemsScheduled':
          if (this.getMore.itemsScheduled) {
            this.page.itemsScheduled++;
            this.fetchAcademiesData('itemsScheduled');
          }
          break;
        case 'itemsByRequest':
          if (this.getMore.itemsByRequest) {
            this.page.itemsByRequest++;
            this.fetchAcademiesData('itemsByRequest');
          }
          break;
      }
    },
    resetFetchDataOptions() {
      this.loading.itemsScheduled = true;
      this.loading.itemsByRequest = true;
      this.page.itemsScheduled = 1;
      this.page.itemsByRequest = 1;
      this.itemsScheduled = [];
      this.itemsByRequest = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  margin-top: 100px;
  padding-bottom: 60px;
}
.page-desc {
  max-width: 550px;
}
</style>
